import { Chain } from 'src/app/shared/enums';

export const environment = {
  envName: 'test',
  production: true,
  apiUrl: 'https://demo.triangels.vip:444/',
  frontUrl: 'https://tta.softdds.com/',
  tokenUrl: 'token',
  botName: 'TriAngelsTestBot',
  botId: '6913915651',
  buildAdmin: false,
};

export const blockexplorers: Map<Chain, string> = new Map<Chain, string>([
  [Chain.Ethereum, 'https://sepolia.etherscan.io/'],
  [Chain.Arbitrum, 'https://sepolia.arbiscan.io/'],
]);

export const contractAddresses = {
  TTA: '0x753E0eCA86a6d78bEd4204cC1Af9beFE2793672B',
  iTTA: '0x76690CCFEad3de272b5E65f03D2D93cf64379cEE',
};

export const WalletConnect = {
  ProjectId: '73d83a30118c7e326b7ab38f5985520b',
};
